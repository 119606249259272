.certificate-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: all 0.3s ease;
    justify-content: space-between;
}


.certificate-logo {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.certificate-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1rem;
}

.certificate-description {
    font-size: 12px;
    color: var(--secondary-color);
}

.certificate-title {
    flex: 1;
    font-size: 15px;
    font-weight: 700;
    margin-top: -3px;
    color: var(--secondary-color);
}

.certificate-link-container {
    text-align: center;
    transition: all 0.3s ease;
}

.certificate-link {
    text-decoration: none;
    color: var(--secondary-color);
}

.certificate-link-text {
    font-size: 10px;
}

.certificate-link-icon {
    font-size: 1.5rem;
    transition: all 0.3s ease;
}