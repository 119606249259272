/* .all-certificates-container {
    display: block;
    padding-bottom: 40px;
} */

.all-certificates-certificate {
    display: flex;
    width: 100%;
}

.certificates-body {
    margin-bottom: 10px;
}